export default [
    {
        path: "/financialCenter/agencyPrice/resourceProxyPrice",
        name: "financialCenter.agencyPrice.resourceProxyPrice",
        component: () =>
            import(
                "@views/financial-center/agency-price/resource-proxy-price/index.vue"
                ),
        meta: {
            pageName: "资源代理价格",
            moduleTitle: "代理价格",
        },
    },
];
